import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/businesses/Screen1.generated';
import { Screen2 } from '../../../components/images/support/businesses/Screen2.generated';
import { Screen3 } from '../../../components/images/support/businesses/Screen3.generated';
import { Screen4 } from '../../../components/images/support/businesses/Screen4.generated';
import { Screen5 } from '../../../components/images/support/businesses/Screen5.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "add-a-business-"
    }}>{`Add a Business 🏢`}</h1>
    <p>{`In Canyou we have the ability to create multiple businesses. This
allows us to organise our members, workflows and certifications into
seperate profiles so that it's easier to manage.`}</p>
    <h3 {...{
      "id": "lets-add-a-business"
    }}>{`Let's add a business`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Select one of your existing businesses from the drop down. If you do not select a business you will not be able to access the `}<strong parentName="li">{`Register business`}</strong>{` button.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the profile button in the top right corner.`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Register business`}</strong>{` button.`}</li>
    </ol>

    <Screen3 mdxType="Screen3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Add the business name and ABN then click the `}<strong parentName="li">{`Register`}</strong>{` button.`}</li>
    </ol>

    <Screen4 mdxType="Screen4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`You can now see the businesses is registered. Swap between your businesses by selecting from the drop down menu`}</li>
    </ol>

    <Screen5 mdxType="Screen5" />
    <h1 {...{
      "id": "all-done-business-registered-"
    }}>{`All done, business registered 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      